
import img from './../../images/Admin/ava_D.png'


const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

const toDataURL = async (url) => await fetch(url)
    .then(response => response.blob())
    .then(async (blob) => await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
    }))


const getFile = async () => {
    let file;
    let str = img;
    try {
        if(str.substring(0,4)==="data"){
            file = dataURLtoFile(img, "defaultAvatar");
        }
        else{
            await toDataURL(img)
                .then(dataUrl => {
                    // console.log('RESULT:'+keyFile, dataUrl)
                    file = dataURLtoFile(dataUrl, "defaultAvatar");
                })
        }
    }
    catch (error){
        // console.log(error)
    }

    return file
}


export default getFile;