import React, { useState } from 'react';
import classes from '../Semantics/Header/Header.module.css';
import InputMask from 'react-input-mask';
import PhoneService from '../../Services/PhoneService';
import classes2 from './ModalForm.module.css';

const ModalForm = ({ isFormPhoneMe, setIsFormPhoneMe }) => {
  const [isLouding, setIsLouding] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [phone, setPhone] = useState('');
  const [userPhoneError, setUserPhoneError] = useState('');
  const [formState, setFormState] = useState(0);

  const requestPhone = async () => {
    if (phone.length < 3) {
      setUserPhoneError('Это поле обязательно для заполнения!');
      return;
    } else {
      setUserPhoneError('');
    }

    let uPhone =
      phone[3] +
      phone[4] +
      phone[5] +
      phone[7] +
      phone[8] +
      phone[9] +
      phone[11] +
      phone[12] +
      phone[14] +
      phone[15];

    if (uPhone.indexOf('_') >= 0) {
      setUserPhoneError('Введён некорректный номер телефона!');
      return;
    } else {
      setUserPhoneError('');
    }

    setFormState(2);
    const response = await PhoneService.postFormCol(uPhone);
    setIsLouding(false);
    response ? setFormState(1) : setFormState(3);
  };

  return (
    <div
      className={classes2.ModalForm}
      onClick={(e) => {
        e.stopPropagation();
        setIsFormPhoneMe(false);
      }}
    >
      <div
        className={classes2.Form}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/*<div className={classes.infoFormActive} >*/}
        <div className={classes.close}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsFormPhoneMe(false);
            }}
          >
            ✕
          </div>
        </div>
        {formState === 0 ? (
          <>
            <InputMask
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              mask="+7-999-999-99-99"
              type="phone"
              placeholder={'Ваш номер телефона'}
              style={{ marginTop: '0px' }}
              required={true}
              className={classes.inputForm}
            />
            <div className={classes.inputError}>{userPhoneError}</div>
            <input
              onClick={requestPhone}
              type="button"
              value={'Перезвоните мне'}
              style={{ marginTop: '20px' }}
              className={classes.button}
            />
          </>
        ) : formState === 1 ? (
          <div className={classes.resultForm}>
            Ваша заявка успешно отправлена оператору!
          </div>
        ) : formState === 2 ? (
          <div className={classes.resultForm}>Отправка...</div>
        ) : (
          <div className={classes.resultForm}>
            Произошла ошибка, пожалуйста попробуйте отправить заявку позже.
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalForm;
