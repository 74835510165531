import React, {useContext, useEffect, useState} from 'react';
import classes from './ChangeTextInput.module.css'
import imgSave from "../../../images/Admin/icon-save.png";
import imgTrue from "../../../images/Admin/galka .png";
import imgReset from "../../../images/Admin/reset.png";
import imgError from "../../../images/Admin/znak.png";
import gifLouder from '../../../images/Admin/louder.gif'
import {GlobalContext} from "../../../contexts/GlobalContext";
import AdminService from "../../../Services/AdminService";

const ChangeTextInput = ({title = "", keyC="", value = "", device="a" }) => {
    const {content, setContent} = useContext(GlobalContext)

    const [stateValue, setStateValue] = useState("");
    const [isFocus, setIsFocus] = useState(false);

    const deviceList = {
        a:classes.input,
        d:classes.inputDesktop,
        m:classes.inputMobile
    }

    const [isCheckButton, setIsCheckButton] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLouder, setIsLouder] = useState(false);


    useEffect(()=>{
        setStateValue(content[keyC]);
    }, [content[keyC]]);

    const change = (e) => {
        setIsCheckButton(true)
        setIsError(false)
        setStateValue(e.target.value);
    }

    const save = async () => {
        setIsLouder(true)
        if( await AdminService.upTextValue(keyC, stateValue)){
            setContent({
                ...content,
                [keyC]:stateValue
            });
            setIsError(false)
            setIsCheckButton(false)
        }
        else{
            setIsError(true)
            setIsCheckButton(false)
        }
        setIsLouder(false)

    }

    const reset = () => {
        setStateValue(content[keyC])
        setIsCheckButton(false)
        setIsError(false)
    }

    return (
        <div className={classes.adminInput}>
            <div className={classes.title}>
                {title}
            </div>
            <div className={classes.controls}>
                {/*<input className={deviceList[device]} value={stateValue} onChange={(e)=>change(e)} type="text"/>*/}
                <textarea wrap="off" onFocus={()=>setIsFocus(true)} onBlur={()=>setIsFocus(false)} className={deviceList[device]} value={stateValue} onChange={(e)=>change(e)}>

                </textarea>
                <>
                    {
                        device==="m"?<br/>:null
                    }
                </>

                {
                    isFocus?
                        <></>
                        :
                    !isLouder?
                    !isError?
                    isCheckButton
                        ?
                        <>
                    <button onClick={save} className={classes.saveButton}>
                        <img src={imgSave} alt=""/>
                    </button>
                    <button onClick={reset} className={classes.saveResetButton}>
                    <img src={imgReset} alt=""/>
                    </button>
                        </>
                        :
                    <button disabled={true} className={classes.saveTrueButton}>
                    <img src={imgTrue} alt=""/>
                    </button>
                        :
                        <>
                    <button onClick={save} className={classes.saveErrorButton}>
                        <img src={imgError} alt=""/>
                    </button>
                    <button onClick={reset} className={classes.saveResetButton}>
                        <img src={imgReset} alt=""/>
                    </button>
                        </>
                        :
                    <button disabled={true} className={classes.saveButton}>
                        <img src={gifLouder} alt=""/>
                    </button>
                }

            </div>

        </div>
    );
};

export default ChangeTextInput;