export default [
    {id:9644890505,phone:9644890505, tariff_price:790},
    {id:9688630505,phone:9688630505, tariff_price:790},
    {id:9584960505,phone:9584960505, tariff_price:790},
    {id:9697640505,phone:9697640505, tariff_price:790},
    {id:9676380505,phone:9676380505, tariff_price:790},
    {id:9062579595,phone:9062579595, tariff_price:790},
    {id:9623149595,phone:9623149595, tariff_price:790},
    {id:9617249595,phone:9617249595, tariff_price:790},
    {id:9632789595,phone:9632789595, tariff_price:790},
    {id:9068649595,phone:9068649595, tariff_price:790},
    {id:9644319595,phone:9644319595, tariff_price:790},
    {id:9046219595,phone:9046219595, tariff_price:790},
    {id:9628249595,phone:9628249595, tariff_price:790},
    {id:9638530707,phone:9638530707, tariff_price:790},
    {id:9662890707,phone:9662890707, tariff_price:790},
    {id:9683560707,phone:9683560707, tariff_price:790},
    {id:9655880707,phone:9655880707, tariff_price:790},
    {id:9668640707,phone:9668640707, tariff_price:790},
    {id:9645927777,phone:9645927777, tariff_price:790},
    {id:9652947777,phone:9652947777, tariff_price:790},
    {id:9660247777,phone:9660247777, tariff_price:790},
    {id:9661427777,phone:9661427777, tariff_price:790},
    {id:9582687777,phone:9582687777, tariff_price:790},
    {id:9913267777,phone:9913267777, tariff_price:790},
    {id:9628197777,phone:9628197777, tariff_price:790},
    {id:9648267777,phone:9648267777, tariff_price:790},
    {id:9089647777,phone:9089647777, tariff_price:790},
    {id:9523917777,phone:9523917777, tariff_price:790},
    {id:9658327777,phone:9658327777, tariff_price:790},
    {id:9303157777,phone:9303157777, tariff_price:790},
    {id:9328617777,phone:9328617777, tariff_price:790},
    {id:9539817777,phone:9539817777, tariff_price:790},
    {id:9651159999,phone:9651159999, tariff_price:790},
    {id:9084649999,phone:9084649999, tariff_price:790},
    {id:9616769999,phone:9616769999, tariff_price:790},
    {id:9346639999,phone:9346639999, tariff_price:790},
    {id:9650239999,phone:9650239999, tariff_price:790},
    {id:9651759999,phone:9651759999, tariff_price:790},
    {id:9652319999,phone:9652319999, tariff_price:790},
    {id:9660129999,phone:9660129999, tariff_price:790},
    {id:9513749999,phone:9513749999, tariff_price:790},
    {id:9374519999,phone:9374519999, tariff_price:790},
    {id:9513479999,phone:9513479999, tariff_price:790},
    {id:9523679999,phone:9523679999, tariff_price:790},
    {id:9516539999,phone:9516539999, tariff_price:790},
    {id:9523719999,phone:9523719999, tariff_price:790},
    {id:9306719999,phone:9306719999, tariff_price:790},
    {id:9957689999,phone:9957689999, tariff_price:790},
    {id:9830749999,phone:9830749999, tariff_price:790},
    {id:9856039999,phone:9856039999, tariff_price:790},
    {id:9047169999,phone:9047169999, tariff_price:790},
    {id:9325319999,phone:9325319999, tariff_price:790},
    {id:9325409999,phone:9325409999, tariff_price:790},
    {id:9587659999,phone:9587659999, tariff_price:790},
    {id:9062270000,phone:9062270000, tariff_price:790},
    {id:9393380000,phone:9393380000, tariff_price:790},
    {id:9613230000,phone:9613230000, tariff_price:790},
    {id:9625570000,phone:9625570000, tariff_price:790},
    {id:9346650000,phone:9346650000, tariff_price:790},
    {id:9587960000,phone:9587960000, tariff_price:790},
    {id:9393450000,phone:9393450000, tariff_price:790},
    {id:9685930000,phone:9685930000, tariff_price:790},
    {id:9685490000,phone:9685490000, tariff_price:790},
    {id:9628140000,phone:9628140000, tariff_price:790},
    {id:9627960000,phone:9627960000, tariff_price:790},
    {id:9673820000,phone:9673820000, tariff_price:790},
    {id:9673720000,phone:9673720000, tariff_price:790},
    {id:9676230000,phone:9676230000, tariff_price:790},
    {id:9615730000,phone:9615730000, tariff_price:790},
    {id:9842690000,phone:9842690000, tariff_price:790},
    {id:9538260000,phone:9538260000, tariff_price:790},
    {id:9524680000,phone:9524680000, tariff_price:790},
    {id:9687600008,phone:9687600008, tariff_price:790},
    {id:9687600009,phone:9687600009, tariff_price:790},
    {id:9671400002,phone:9671400002, tariff_price:790},
    {id:9692800003,phone:9692800003, tariff_price:790},
    {id:9693400009,phone:9693400009, tariff_price:790},
    {id:9646200009,phone:9646200009, tariff_price:790},
    {id:9671900003,phone:9671900003, tariff_price:790},
    {id:9653700008,phone:9653700008, tariff_price:790},
    {id:9635100004,phone:9635100004, tariff_price:790},
    {id:9646700003,phone:9646700003, tariff_price:790},
    {id:9657300008,phone:9657300008, tariff_price:790},
    {id:9658100009,phone:9658100009, tariff_price:790},
    {id:9632500003,phone:9632500003, tariff_price:790},
    {id:9697200004,phone:9697200004, tariff_price:790},
    {id:9643400003,phone:9643400003, tariff_price:790},
    {id:9681700006,phone:9681700006, tariff_price:790},
    {id:9641900002,phone:9641900002, tariff_price:790},
    {id:9381400003,phone:9381400003, tariff_price:790},
    {id:9257800006,phone:9257800006, tariff_price:790},
    {id:9257500004,phone:9257500004, tariff_price:790},
    {id:9366200004,phone:9366200004, tariff_price:790},
    {id:9257900002,phone:9257900002, tariff_price:790},
    {id:9636700002,phone:9636700002, tariff_price:790},
    {id:9637600004,phone:9637600004, tariff_price:790},
    {id:9645200004,phone:9645200004, tariff_price:790},
    {id:9645600003,phone:9645600003, tariff_price:790},
    {id:9645600009,phone:9645600009, tariff_price:790},
    {id:9645900003,phone:9645900003, tariff_price:790},
    {id:9652100003,phone:9652100003, tariff_price:790},
    {id:9653200006,phone:9653200006, tariff_price:790},
    {id:9653800009,phone:9653800009, tariff_price:790},
    {id:9672500006,phone:9672500006, tariff_price:790},
    {id:9672500008,phone:9672500008, tariff_price:790},
    {id:9672800004,phone:9672800004, tariff_price:790},
    {id:9686500008,phone:9686500008, tariff_price:790},
    {id:9687200006,phone:9687200006, tariff_price:790},
    {id:9691700004,phone:9691700004, tariff_price:790},
    {id:9691800004,phone:9691800004, tariff_price:790},
    {id:9692800006,phone:9692800006, tariff_price:790},
    {id:9693400003,phone:9693400003, tariff_price:790},
    {id:9254300006,phone:9254300006, tariff_price:790},
    {id:9645600002,phone:9645600002, tariff_price:790},
    {id:9667900003,phone:9667900003, tariff_price:790},
    {id:9669700006,phone:9669700006, tariff_price:790},
    {id:9619200008,phone:9619200008, tariff_price:790},
    {id:9647900002,phone:9647900002, tariff_price:790},
    {id:9617900003,phone:9617900003, tariff_price:790},
    {id:9607500003,phone:9607500003, tariff_price:790},
    {id:9668300006,phone:9668300006, tariff_price:790},
    {id:9668700003,phone:9668700003, tariff_price:790},
    {id:9662220007,phone:9662220007, tariff_price:790},
    {id:9666661113,phone:9666661113, tariff_price:790},
    {id:9666661118,phone:9666661118, tariff_price:790},
    {id:9666662225,phone:9666662225, tariff_price:790},
    {id:9666662229,phone:9666662229, tariff_price:790},
    {id:9666663334,phone:9666663334, tariff_price:790},
    {id:9666664442,phone:9666664442, tariff_price:790},
    {id:9666664448,phone:9666664448, tariff_price:790},
    {id:9683330006,phone:9683330006, tariff_price:790},
    {id:9682220006,phone:9682220006, tariff_price:790},
    {id:9678880005,phone:9678880005, tariff_price:790},
    {id:9636660002,phone:9636660002, tariff_price:790},
    {id:9651110004,phone:9651110004, tariff_price:790},
    {id:9691110002,phone:9691110002, tariff_price:790},
    {id:9664440002,phone:9664440002, tariff_price:790},
    {id:9694440003,phone:9694440003, tariff_price:790},
    {id:9694440008,phone:9694440008, tariff_price:790},
    {id:9688884446,phone:9688884446, tariff_price:790},
    {id:9688889990,phone:9688889990, tariff_price:790},
    {id:9699992221,phone:9699992221, tariff_price:790},
    {id:9699993331,phone:9699993331, tariff_price:790},
    {id:9699995558,phone:9699995558, tariff_price:790},
    {id:9699998881,phone:9699998881, tariff_price:790},
    {id:9699998886,phone:9699998886, tariff_price:790},
    {id:9668887779,phone:9668887779, tariff_price:790},
    {id:9636664448,phone:9636664448, tariff_price:790},
    {id:9683332224,phone:9683332224, tariff_price:790},
    {id:9643336669,phone:9643336669, tariff_price:790},
    {id:9651113336,phone:9651113336, tariff_price:790},
    {id:9651113337,phone:9651113337, tariff_price:790},
    {id:9651114440,phone:9651114440, tariff_price:790},
    {id:9651114442,phone:9651114442, tariff_price:790},
    {id:9651114446,phone:9651114446, tariff_price:790},
    {id:9651114448,phone:9651114448, tariff_price:790},
    {id:9651114449,phone:9651114449, tariff_price:790},
    {id:9651115554,phone:9651115554, tariff_price:790},
    {id:9651115558,phone:9651115558, tariff_price:790},
    {id:9651116663,phone:9651116663, tariff_price:790},
    {id:9651116664,phone:9651116664, tariff_price:790},
    {id:9651118884,phone:9651118884, tariff_price:790},
    {id:9651118886,phone:9651118886, tariff_price:790},
    {id:9636661114,phone:9636661114, tariff_price:790},
    {id:9663331114,phone:9663331114, tariff_price:790},
    {id:9663331117,phone:9663331117, tariff_price:790},
    {id:9663331119,phone:9663331119, tariff_price:790},
    {id:9663332224,phone:9663332224, tariff_price:790},
    {id:9663332225,phone:9663332225, tariff_price:790},
    {id:9663332227,phone:9663332227, tariff_price:790},
    {id:9663332228,phone:9663332228, tariff_price:790},
    {id:9663334440,phone:9663334440, tariff_price:790},
    {id:9663334441,phone:9663334441, tariff_price:790},
    {id:9663335551,phone:9663335551, tariff_price:790},
    {id:9663335552,phone:9663335552, tariff_price:790},
    {id:9663335554,phone:9663335554, tariff_price:790},
    {id:9663336664,phone:9663336664, tariff_price:790},
    {id:9663336665,phone:9663336665, tariff_price:790},
    {id:9663337771,phone:9663337771, tariff_price:790},
    {id:9663338882,phone:9663338882, tariff_price:790},
    {id:9663338889,phone:9663338889, tariff_price:790},
    {id:9671112227,phone:9671112227, tariff_price:790},
    {id:9671113330,phone:9671113330, tariff_price:790},
    {id:9671113335,phone:9671113335, tariff_price:790},
    {id:9671114443,phone:9671114443, tariff_price:790},
    {id:9671114445,phone:9671114445, tariff_price:790},
    {id:9671114449,phone:9671114449, tariff_price:790},
    {id:9671115554,phone:9671115554, tariff_price:790},
    {id:9671115556,phone:9671115556, tariff_price:790},
    {id:9671115558,phone:9671115558, tariff_price:790},
    {id:9671115559,phone:9671115559, tariff_price:790},
    {id:9671116664,phone:9671116664, tariff_price:790},
    {id:9671117775,phone:9671117775, tariff_price:790},
    {id:9671118882,phone:9671118882, tariff_price:790},
    {id:9671118883,phone:9671118883, tariff_price:790},
    {id:9671118884,phone:9671118884, tariff_price:790},
    {id:9671118886,phone:9671118886, tariff_price:790},
    {id:9671119998,phone:9671119998, tariff_price:790},
    {id:9672223335,phone:9672223335, tariff_price:790},
    {id:9672223336,phone:9672223336, tariff_price:790},
    {id:9672224440,phone:9672224440, tariff_price:790},
    {id:9672224443,phone:9672224443, tariff_price:790},
    {id:9672224446,phone:9672224446, tariff_price:790},
    {id:9672224447,phone:9672224447, tariff_price:790},
    {id:9672224449,phone:9672224449, tariff_price:790},
    {id:9672225550,phone:9672225550, tariff_price:790},
    {id:9672225554,phone:9672225554, tariff_price:790},
    {id:9672225556,phone:9672225556, tariff_price:790},
    {id:9672226661,phone:9672226661, tariff_price:790},
    {id:9672226664,phone:9672226664, tariff_price:790},
    {id:9672226665,phone:9672226665, tariff_price:790},
    {id:9672226668,phone:9672226668, tariff_price:790},
    {id:9672226669,phone:9672226669, tariff_price:790},
    {id:9684441113,phone:9684441113, tariff_price:790},
    {id:9684443338,phone:9684443338, tariff_price:790},
    {id:9684446660,phone:9684446660, tariff_price:790},
    {id:9684446661,phone:9684446661, tariff_price:790},
    {id:9684446662,phone:9684446662, tariff_price:790},
    {id:9684447772,phone:9684447772, tariff_price:790},
    {id:9684449990,phone:9684449990, tariff_price:790},
    {id:9686661110,phone:9686661110, tariff_price:790},
    {id:9686662221,phone:9686662221, tariff_price:790},
    {id:9686664447,phone:9686664447, tariff_price:790},
    {id:9634441118,phone:9634441118, tariff_price:790},
    {id:9691112224,phone:9691112224, tariff_price:790},
    {id:9691112225,phone:9691112225, tariff_price:790},
    {id:9691113332,phone:9691113332, tariff_price:790},
    {id:9691113334,phone:9691113334, tariff_price:790},
    {id:9691114443,phone:9691114443, tariff_price:790},
    {id:9691114447,phone:9691114447, tariff_price:790},
    {id:9691114448,phone:9691114448, tariff_price:790},
    {id:9691114449,phone:9691114449, tariff_price:790},
    {id:9691115559,phone:9691115559, tariff_price:790},
    {id:9691118885,phone:9691118885, tariff_price:790},
    {id:9691118886,phone:9691118886, tariff_price:790},
    {id:9691119996,phone:9691119996, tariff_price:790},
    {id:9664441118,phone:9664441118, tariff_price:790},
    {id:9664442223,phone:9664442223, tariff_price:790},
    {id:9664442225,phone:9664442225, tariff_price:790},
    {id:9664442227,phone:9664442227, tariff_price:790},
    {id:9664443331,phone:9664443331, tariff_price:790},
    {id:9662221114,phone:9662221114, tariff_price:790},
    {id:9662223339,phone:9662223339, tariff_price:790},
    {id:9662225551,phone:9662225551, tariff_price:790},
    {id:9662225553,phone:9662225553, tariff_price:790},
    {id:9662225554,phone:9662225554, tariff_price:790},
    {id:9662228883,phone:9662228883, tariff_price:790},
    {id:9662228884,phone:9662228884, tariff_price:790},
    {id:9662228886,phone:9662228886, tariff_price:790},
    {id:9662229990,phone:9662229990, tariff_price:790},
    {id:9662229998,phone:9662229998, tariff_price:790},
    {id:9664445557,phone:9664445557, tariff_price:790},
    {id:9664445559,phone:9664445559, tariff_price:790},
    {id:9664447772,phone:9664447772, tariff_price:790},
    {id:9664447779,phone:9664447779, tariff_price:790},
    {id:9664448883,phone:9664448883, tariff_price:790},
    {id:9664449992,phone:9664449992, tariff_price:790},
    {id:9694441112,phone:9694441112, tariff_price:790},
    {id:9694441113,phone:9694441113, tariff_price:790},
    {id:9694441115,phone:9694441115, tariff_price:790},
    {id:9694441116,phone:9694441116, tariff_price:790},
    {id:9694441117,phone:9694441117, tariff_price:790},
    {id:9694441119,phone:9694441119, tariff_price:790},
    {id:9694442221,phone:9694442221, tariff_price:790},
    {id:9694442227,phone:9694442227, tariff_price:790},
    {id:9694442229,phone:9694442229, tariff_price:790},
    {id:9694443331,phone:9694443331, tariff_price:790},
    {id:9694443338,phone:9694443338, tariff_price:790},
    {id:9694443339,phone:9694443339, tariff_price:790},
    {id:9694446665,phone:9694446665, tariff_price:790},
    {id:9694446668,phone:9694446668, tariff_price:790},
    {id:9694447772,phone:9694447772, tariff_price:790},
    {id:9694447778,phone:9694447778, tariff_price:790},
    {id:9694448882,phone:9694448882, tariff_price:790},
    {id:9694448886,phone:9694448886, tariff_price:790},
    {id:9694448889,phone:9694448889, tariff_price:790},
    {id:9694449993,phone:9694449993, tariff_price:790},
    {id:9612221115,phone:9612221115, tariff_price:790},
    {id:9612225557,phone:9612225557, tariff_price:790},
    {id:9612228884,phone:9612228884, tariff_price:790},
    {id:9682221117,phone:9682221117, tariff_price:790},
    {id:9682221119,phone:9682221119, tariff_price:790},
    {id:9682223336,phone:9682223336, tariff_price:790},
    {id:9682224446,phone:9682224446, tariff_price:790},
    {id:9682224449,phone:9682224449, tariff_price:790},
    {id:9682224441,phone:9682224441, tariff_price:790},
    {id:9682224443,phone:9682224443, tariff_price:790},
    {id:9682224445,phone:9682224445, tariff_price:790},
    {id:9682225559,phone:9682225559, tariff_price:790},
    {id:9682226663,phone:9682226663, tariff_price:790},
    {id:9682226664,phone:9682226664, tariff_price:790},
    {id:9682226668,phone:9682226668, tariff_price:790},
    {id:9682226669,phone:9682226669, tariff_price:790},
    {id:9682227774,phone:9682227774, tariff_price:790},
    {id:9682228884,phone:9682228884, tariff_price:790},
    {id:9682228881,phone:9682228881, tariff_price:790},
    {id:9682229990,phone:9682229990, tariff_price:790},
    {id:9682229991,phone:9682229991, tariff_price:790},
    {id:9682229993,phone:9682229993, tariff_price:790},
    {id:9682229994,phone:9682229994, tariff_price:790},
    {id:9682229996,phone:9682229996, tariff_price:790},
    {id:9682229998,phone:9682229998, tariff_price:790},
    {id:9634441116,phone:9634441116, tariff_price:790},
    {id:9634441110,phone:9634441110, tariff_price:790},
    {id:9606665552,phone:9606665552, tariff_price:790},
    {id:9613332225,phone:9613332225, tariff_price:790},
    {id:9613332229,phone:9613332229, tariff_price:790},
    {id:9613334446,phone:9613334446, tariff_price:790},
    {id:9613334448,phone:9613334448, tariff_price:790},
    {id:9613335554,phone:9613335554, tariff_price:790},
    {id:9613336662,phone:9613336662, tariff_price:790},
    {id:9613336665,phone:9613336665, tariff_price:790},
    {id:9613337772,phone:9613337772, tariff_price:790},
    {id:9613338886,phone:9613338886, tariff_price:790},
    {id:9613339992,phone:9613339992, tariff_price:790},
    {id:9631114443,phone:9631114443, tariff_price:790},
    {id:9631116662,phone:9631116662, tariff_price:790},
    {id:9631116669,phone:9631116669, tariff_price:790},
    {id:9631119996,phone:9631119996, tariff_price:790},
    {id:9656663337,phone:9656663337, tariff_price:790},
    {id:9681113336,phone:9681113336, tariff_price:790},
    {id:9681114442,phone:9681114442, tariff_price:790},
    {id:9681114445,phone:9681114445, tariff_price:790},
    {id:9681114446,phone:9681114446, tariff_price:790},
    {id:9681115554,phone:9681115554, tariff_price:790},
    {id:9681115559,phone:9681115559, tariff_price:790},

]