import React, { useEffect, useState } from 'react';
import classes from './Header.module.css';
import logo from './../../../images/logo.png';
import logoDesktop from './../../../images/logoDesktop.png';
import menu from './../../../images/menu.png';
import menuClose from './../../../images/menu_close.png';
import phoneImg from './../../../images/phone.png';
import Menu from '../../Menu/Menu';
import InputMask from 'react-input-mask';
import PhoneService from '../../../Services/PhoneService';
import StopIntervalHelper from '../../Helpers/StopIntervalHelper';

const Header = () => {
  const [isMenu, setIsMenu] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [phone, setPhone] = useState('');
  const [userPhoneError, setUserPhoneError] = useState('');
  const [formState, setFormState] = useState(0);

  const a_href = (name) => {
    StopIntervalHelper();
    document.getElementsByName(name)[0].scrollIntoView();
  };

  const requestPhone = async () => {
    if (phone.length < 3) {
      setUserPhoneError('Это поле обязательно для заполнения!');
      return;
    } else {
      setUserPhoneError('');
    }

    let uPhone =
      phone[3] +
      phone[4] +
      phone[5] +
      phone[7] +
      phone[8] +
      phone[9] +
      phone[11] +
      phone[12] +
      phone[14] +
      phone[15];

    if (uPhone.indexOf('_') >= 0) {
      setUserPhoneError('Введён некорректный номер телефона!');
      return;
    } else {
      setUserPhoneError('');
    }

    setFormState(2);
    const response = await PhoneService.postFormCol(uPhone);
    response ? setFormState(1) : setFormState(3);
  };

  return (
    <div className={classes.HeaderTop}>
      <div className={classes.container}>
        <div className={classes.empty}></div>

        <div className={classes.content}>
          <div className={classes.logo}>
            <div className={classes.img}>
              <img src={logo} alt="" />
            </div>
            <div className={classes.imgDesktop}>
              <img src={logoDesktop} alt="" />
            </div>
          </div>

          <div className={classes.buttonBlock}>
            <a onClick={() => a_href('SixPage')}>
              <input
                type="button"
                className={classes.noManyButtonMobile}
                value="НЕ ПЛАТИТЬ ЗА СВЯЗЬ"
              />
              <input
                type="button"
                className={classes.noManyButton}
                value="не платить за связь"
              />
            </a>
          </div>
          <div className={classes.info} onClick={() => setIsForm(true)}>
            <div className={classes.phone}>+7 495 966 99 99</div>
            <div className={classes.infoText}>КРУГЛОСУТОЧНЫЙ</div>

            <div className={isForm ? classes.infoFormActive : classes.infoForm}>
              <div className={classes.close}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsForm(false);
                  }}
                >
                  ✕
                </div>
              </div>
              {formState === 0 ? (
                <>
                  <InputMask
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    mask="+7-999-999-99-99"
                    type="phone"
                    placeholder={'Ваш номер телефона'}
                    style={{ marginTop: '0px' }}
                    required={true}
                    className={classes.inputForm}
                  />
                  <div className={classes.inputError}>{userPhoneError}</div>
                  <input
                    onClick={requestPhone}
                    type="button"
                    value={'Перезвоните мне'}
                    style={{ marginTop: '20px' }}
                    className={classes.button}
                  />
                </>
              ) : formState === 1 ? (
                <div className={classes.resultForm}>
                  Ваша заявка успешно отправлена оператору!
                </div>
              ) : (
                <div className={classes.resultForm}>
                  Произошла ошибка, пожалуйста попробуйте отправить заявку
                  позже.
                </div>
              )}
            </div>
          </div>

          <div className={classes.infoMobile} onClick={() => setIsForm(true)}>
            <img src={phoneImg} alt="" />
            <div
              className={isForm ? classes.infoFormActive : classes.infoForm}
              style={{ marginLeft: '-220px' }}
            >
              <div className={classes.close}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsForm(false);
                  }}
                >
                  ✕
                </div>
              </div>
              {formState === 0 ? (
                <>
                  <InputMask
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    mask="+7-999-999-99-99"
                    type="phone"
                    placeholder={'Ваш номер телефона'}
                    style={{ marginTop: '0px' }}
                    required={true}
                    className={classes.inputForm}
                  />
                  <div className={classes.inputError}>{userPhoneError}</div>
                  <input
                    onClick={requestPhone}
                    type="button"
                    value={'Перезвоните мне'}
                    style={{ marginTop: '20px' }}
                    className={classes.button}
                  />
                </>
              ) : formState === 1 ? (
                <div className={classes.resultForm}>
                  Ваша заявка успешно отправлена оператору!
                </div>
              ) : formState === 2 ? (
                <div className={classes.resultForm}>Отправка...</div>
              ) : (
                <div className={classes.resultForm}>
                  Произошла ошибка, пожалуйста попробуйте отправить заявку
                  позже.
                </div>
              )}
            </div>
          </div>

          <div className={classes.menuMobile}>
            <img
              src={!isMenu ? menu : menuClose}
              alt=""
              onClick={() => setIsMenu(!isMenu)}
            />
            {isMenu ? (
              <Menu isMenu={isMenu} setMenu={setIsMenu} />
            ) : (
              <div></div>
            )}
          </div>
        </div>

        <div className={classes.menu}>
          <img
            src={!isMenu ? menu : menuClose}
            alt=""
            onClick={() => setIsMenu(!isMenu)}
          />
          {/*<Menu />*/}
          {isMenu ? <Menu isMenu={isMenu} setMenu={setIsMenu} /> : <div></div>}
        </div>
      </div>
    </div>
  );
};

export default Header;
