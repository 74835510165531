import React, { useContext, useEffect, useState } from 'react';
import classes from './TenPage.module.css';

import left from './../../../images/TenPage/layer_4.png';
import right from './../../../images/TenPage/layer_4_2.png';
import PhoneService from '../../../Services/PhoneService';
import { GlobalContext } from '../../../contexts/GlobalContext';
import StopIntervalHelper from '../../Helpers/StopIntervalHelper';

const TenPage = () => {
  const [list, setList] = useState([]);
  const [slide, setSlide] = useState(0);
  const [isForm, setIsForm] = useState(false);

  const [nameError, setNameError] = useState('');
  const [regionError, setRegionError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [name, setName] = useState('');
  const [region, setRegion] = useState('');
  const [message, setMessage] = useState('');

  const [isFormState, setIsFormState] = useState(0);

  // const OpenForm = () => {
  //   setIsForm(true);
  //   StopIntervalHelper();
  //   document.getElementsByName('TenPage')[0].scrollIntoView();
  // };

  // const CloseForm = () => {
  //   setIsForm(false);
  // };

  const slideToLeft = () => {
    if (slide > 0) setSlide(slide - 1);
  };
  const slideToRight = () => {
    if (slide < list.length - 1 && window.screen.width < 425) {
      setSlide(slide + 1);
    } else {
      setSlide(slide + 0);
    }
  };

  const click = async (e) => {
    e.preventDefault();

    if (name.length < 1) {
      setNameError('Это поле обязательно для заполнения!');
      return;
    } else {
      setNameError('');
    }

    if (region.length < 1) {
      setRegionError('Это поле обязательно для заполнения!');
      return;
    } else {
      setRegionError('');
    }

    if (message.length < 1) {
      setMessageError('Это поле обязательно для заполнения!');
      return;
    } else {
      setMessageError('');
    }

    setIsFormState(2);
    const response = await PhoneService.postFormFeedback(name, region, message);

    response ? setIsFormState(1) : setIsFormState(3);
  };

  const { content } = useContext(GlobalContext);

  useEffect(() => {
    setList(content.ten_comments);
  }, [content?.ten_comments]);

  return (
    <div className={classes.Page}>
      <div className="headerClone" />

      <div className={classes.content}>
        <div className={classes.title}>{content?.ten_1}</div>

        <div className={classes.slider}>
          {isForm ? (
            <div className={classes.form}>
              <form action="" className={classes.formContainer}>
                {isFormState === 0 ? (
                  <>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      type="text"
                      className={classes.textInput}
                      placeholder={content?.ten_form_1}
                    />
                    <div className={classes.error}>{nameError}</div>
                    <input
                      onChange={(e) => setRegion(e.target.value)}
                      value={region}
                      type="text"
                      className={classes.textInput}
                      placeholder={content?.ten_form_2}
                    />
                    <div className={classes.error}>{regionError}</div>
                    <textarea
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      className={classes.textareaInput}
                      placeholder={content?.ten_form_3}
                    ></textarea>
                    <div className={classes.error}>{messageError}</div>

                    <input
                      type="submit"
                      className={classes.submit}
                      onClick={(e) => click(e)}
                      value={'Отправить'}
                    />
                  </>
                ) : isFormState === 1 ? (
                  <div className={classes.formResponse}>
                    Ваш отзыв успешно отправлен!
                  </div>
                ) : isFormState === 2 ? (
                  <div className={classes.formResponse}>Отправка...</div>
                ) : (
                  <div className={classes.formResponse}>
                    Ошибка сервера, пожалуйста попробуйте оставить свой отзыв
                    позже.
                  </div>
                )}
              </form>
            </div>
          ) : (
            false
          )}

          <button onClick={slideToLeft} className={classes.leftButton}>
            <img src={left} alt="" />
          </button>

          <div className={classes.feedbacks}>
            {list.map((element, index) => {
              return (
                <div
                  key={index}
                  className={classes.feedback}
                  style={{ transform: 'translateX(' + -265 * slide + 'px)' }}
                >
                  <div className={classes.feedbackImg}>
                    {/*<div className={classes.imgBorder2}>*/}
                    <div className={classes.imgBorder1}>
                      <img src={element.img} alt="" />
                    </div>
                    {/*</div>*/}

                    <div className={classes.userInfo}>
                      {element.name + ', \n' + element.city}
                    </div>
                  </div>

                  <div className={classes.massage}>
                    <p>{element.massage}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <button onClick={slideToRight} className={classes.rightButton}>
            <img src={right} alt="" />
          </button>
        </div>

        {/* {!isForm ?
                    <div className={classes.link} onClick={OpenForm}>
                        {content?.ten_2}
                    </div>
                    :
                    <div className={classes.link} onClick={CloseForm}>
                        {content?.ten_3}
                    </div>
                } */}
      </div>
    </div>
  );
};

export default TenPage;
