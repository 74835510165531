import React, { useContext } from 'react';
import classes from './WhyUsPage.module.css';
import Phone from '../../Phone/Phone';
import { GlobalContext } from '../../../contexts/GlobalContext';
import StopIntervalHelper from '../../Helpers/StopIntervalHelper';

const WhyUsPage = ({ toFormPhone, phone, rates, isForm, setIsForm }) => {
  const { content } = useContext(GlobalContext);

  const a1 = () => {
    StopIntervalHelper();
    document.getElementsByName('SevenPage')[0].scrollIntoView();
  };
  const a2 = () => {
    StopIntervalHelper();
    document.getElementsByName('SixPage')[0].scrollIntoView();
  };
  return (
    <div className={classes.WhyUsPage}>
      <div className="headerClone"></div>

      <div className={classes.textMobile}>
        <div className={classes.oneBlockText}>
          <div className={classes.textTop}>{content?.one_m_1}</div>
          <div className={classes.textBottom}>{content?.one_m_2}</div>
        </div>
      </div>
      <div className={classes.DesktopContainer}>
        <div className={classes.DesktopContent}>
          <div className={classes.desktopTitle}>
            {content?.one_d_4} <br />
            <span>{content?.one_d_5}</span>
          </div>
          <div className={classes.desktopTitle2}>{content?.one_d_6}</div>
          <div className={classes.desktopTextList}>
            <p>{content?.one_d_7}</p>
            <p>{content?.one_d_8}</p>
            <p>
              {content?.one_d_9}
              <span>
                <a onClick={a1}>{content?.one_d_10}</a>
              </span>
            </p>
            <p>
              {content?.one_d_11}
              <span>
                <a onClick={a2}>{content?.one_d_12}</a>
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className={classes.Phone}>
        <Phone
          toFormPhone={toFormPhone}
          phone={phone}
          rates={rates}
          isForm={isForm}
          setIsForm={setIsForm}
        />
      </div>
    </div>
  );
};

export default WhyUsPage;
