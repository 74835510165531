import React from 'react';
import classes from './Menu.module.css';
import StopIntervalHelper from '../Helpers/StopIntervalHelper';

const Menu = ({ isMenu, setMenu }) => {
  const list = [
    { title: 'Главная', href: 'WhyUsPage' },
    // { title: 'Почему мы', href: 'FourPage' },
    { title: 'Номер \n по любимым цифрам', href: 'ThreePage' },
    // { title: 'Тарифы', href: 'FivePage' },
    { title: 'Можно зарабатывать', href: 'SixPage' },
    // { title: 'Как мы работаем', href: 'EightPage' },
    // { title: 'О компании', href: 'NinePage' },
    { title: 'Отзывы', href: 'TenPage' },
    // { title: 'Вопросы', href: 'ElevenPage' },
    { title: 'Контакты', href: 'TwelvePage' },
  ];

  return (
    <div className={classes.Menu}>
      <div className={classes.container}>
        {list.map((element) => {
          return (
            <div
              onClick={() => {
                StopIntervalHelper();
                setMenu(!isMenu);
                document.getElementsByName(element.href)[0].scrollIntoView();
                // StopIntervalHelper();
                // document.getElementsByName(element.href)[0].scrollIntoView();
              }}
              key={element.href}
              // href={'#'+element.href}
              className={classes.item}
            >
              {element.title}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Menu;
