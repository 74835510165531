import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classes from './Admin.module.css';

import leftB from './images/Admin/leftB.svg';
import rightB from './images/Admin/rightB.svg';
import loudergif from './images/Admin/louder.gif';

import imgSave from './images/Admin/icon-save.png';
import ChangeTextInput from './Components/Modules/ChangeTextInput/ChangeTextInput';
import { GlobalContext } from './contexts/GlobalContext';
import ChangeImageInput from './Components/Modules/ChangeImageInput/ChangeImageInput';
import AdminService from './Services/AdminService';
import ChangeVideoInput from './Components/Modules/ChangeVideoInput/ChangeVideoInput';
import ChangeReviewModule from './Components/Modules/ChangeReviewModule/ChangeReviewModule';
import ButtonReset from './ButtonReset';
import helpInfoImg from './images/Admin/helpInfo.png';

const Admin = () => {
  const [pageActive, setPageActive] = useState(0);
  const [louder, setLouder] = useState(false);
  const [isLouderAuth, setIsLouderAuth] = useState(false);

  const setPagePlus = () => {
    if (pageActive < pages.length - 1) setPageActive(pageActive + 1);
    else setPageActive(0);
  };

  const setPageMinus = () => {
    if (pageActive > 0) setPageActive(pageActive - 1);
    else setPageActive(pages.length - 1);
  };

  const { content, setContent } = useContext(GlobalContext);

  const [pages, setPages] = useState([
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    'F',
  ]);

  const [pagesContents, setPagesContents] = useState([
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    'F',
  ]);

  useEffect(() => {
    setPagesContents([
      <div className={classes.main}>
        <div className={classes.desktop}>
          <ChangeTextInput
            title={'one_d_4'}
            keyC={'one_d_4'}
            device={'d'}
            value={content?.one_d_4}
          />
          <ChangeTextInput
            title={'one_d_5'}
            keyC={'one_d_5'}
            device={'d'}
            value={content?.one_d_5}
          />
          <ChangeTextInput
            title={'one_d_6'}
            keyC={'one_d_6'}
            device={'d'}
            value={content?.one_d_6}
          />
          <ChangeTextInput
            title={'one_d_7'}
            keyC={'one_d_7'}
            device={'d'}
            value={content?.one_d_7}
          />
          <ChangeTextInput
            title={'one_d_8'}
            keyC={'one_d_8'}
            device={'d'}
            value={content?.one_d_8}
          />
          <ChangeTextInput
            title={'one_d_9'}
            keyC={'one_d_9'}
            device={'d'}
            value={content?.one_d_9}
          />
          <ChangeTextInput
            title={'one_d_10'}
            keyC={'one_d_10'}
            device={'d'}
            value={content?.one_d_10}
          />
          <ChangeTextInput
            title={'one_d_11'}
            keyC={'one_d_11'}
            device={'d'}
            value={content?.one_d_11}
          />
          <ChangeTextInput
            title={'one_d_12'}
            keyC={'one_d_12'}
            device={'d'}
            value={content?.one_d_12}
          />
          <ChangeTextInput
            title={'phone_1'}
            keyC={'phone_1'}
            value={content?.phone_1}
          />
          <ChangeTextInput
            title={'phone_2'}
            keyC={'phone_2'}
            value={content?.phone_2}
          />
          <div className={classes.end} />
        </div>

        <div className={classes.mobile}>
          <ChangeTextInput
            title={'one_m_1'}
            keyC={'one_m_1'}
            device={'m'}
            value={content?.one_m_1}
          />
          {/*<ChangeImageInput title={"two_m_img_1"} keyC={"two_m_img_1"} device={"d"} value={content?.nine_img_d_3}/>*/}
          <ChangeTextInput
            title={'one_m_2'}
            keyC={'one_m_2'}
            device={'m'}
            value={content?.one_m_2}
          />
          {/* <ChangeTextInput title={"one_m_3"} keyC={"one_m_3"} device={"m"} value={content?.one_m_3}/> */}
          <div className={classes.end} />
        </div>
      </div>,
      //****************************************************************************************************
      <div className={classes.main}>
        <div className={classes.mobile}>
          <ChangeImageInput
            title={'two_m_img_1'}
            keyC={'two_m_img_1'}
            device={'m'}
            value={content?.two_m_img_1}
          />
          <ChangeTextInput
            title={'two_m_1'}
            keyC={'two_m_1'}
            device={'m'}
            value={content?.two_m_1}
          />
          <ChangeImageInput
            title={'two_m_img_2'}
            keyC={'two_m_img_2'}
            device={'m'}
            value={content?.two_m_img_2}
          />
          <ChangeTextInput
            title={'two_m_2'}
            keyC={'two_m_2'}
            device={'m'}
            value={content?.two_m_2}
          />
          <ChangeImageInput
            title={'two_m_img_3'}
            keyC={'two_m_img_3'}
            device={'m'}
            value={content?.two_m_img_3}
          />
          <ChangeTextInput
            title={'two_m_3'}
            keyC={'two_m_3'}
            device={'m'}
            value={content?.two_m_3}
          />
          <ChangeImageInput
            title={'two_m_img_4'}
            keyC={'two_m_img_4'}
            device={'m'}
            value={content?.two_m_img_4}
          />
          <ChangeTextInput
            title={'two_m_4'}
            keyC={'two_m_4'}
            device={'m'}
            value={content?.two_m_4}
          />
          <div className={classes.end} />
        </div>
      </div>,
      //****************************************************************************************************
      <div className={classes.main}>
        <div className={classes.desktop}>
          <ChangeTextInput
            title={'three_d_1'}
            keyC={'three_d_1'}
            device={'d'}
            value={content?.three_d_1}
          />
          <ChangeTextInput
            title={'three_d_2'}
            keyC={'three_d_2'}
            device={'d'}
            value={content?.three_d_2}
          />
          <ChangeTextInput
            title={'three_d_3'}
            keyC={'three_d_3'}
            device={'d'}
            value={content?.three_d_3}
          />
          <ChangeTextInput
            title={'three_d_4'}
            keyC={'three_d_4'}
            device={'d'}
            value={content?.three_d_4}
          />
          <div className={classes.end} />
        </div>

        <div className={classes.mobile}>
          <ChangeTextInput
            title={'three_m_1'}
            keyC={'three_m_1'}
            device={'m'}
            value={content?.three_m_1}
          />
          <ChangeTextInput
            title={'three_m_2'}
            keyC={'three_m_2'}
            device={'m'}
            value={content?.three_m_2}
          />
          <ChangeTextInput
            title={'three_m_3'}
            keyC={'three_m_3'}
            device={'m'}
            value={content?.three_m_3}
          />
          <div className={classes.end} />
        </div>
      </div>,
      //****************************************************************************************************
      <div className={classes.main}>
        <div className={classes.desktop}>
          <ChangeTextInput
            title={'four_1'}
            keyC={'four_1'}
            value={content?.four_1}
          />
          <ChangeTextInput
            title={'four_2'}
            keyC={'four_2'}
            value={content?.four_2}
          />
          <ChangeTextInput
            title={'four_3'}
            keyC={'four_3'}
            value={content?.four_3}
          />
          <ChangeTextInput
            title={'four_4'}
            keyC={'four_4'}
            value={content?.four_4}
          />
          <ChangeTextInput
            title={'four_5'}
            keyC={'four_5'}
            value={content?.four_5}
          />
          <ChangeTextInput
            title={'four_6'}
            keyC={'four_6'}
            value={content?.four_6}
          />
          <ChangeTextInput
            title={'four_7'}
            keyC={'four_7'}
            value={content?.four_7}
          />
          <div className={classes.end} />
        </div>
      </div>,
      //****************************************************************************************************
      <div className={classes.main}>
        <div className={classes.desktop}>
          <ChangeTextInput
            title={'five_1'}
            keyC={'five_1'}
            value={content?.five_1}
          />
          <ChangeTextInput
            title={'five_2'}
            keyC={'five_2'}
            value={content?.five_2}
          />
          <ChangeTextInput
            title={'five_3'}
            keyC={'five_3'}
            value={content?.five_3}
          />
          <ChangeTextInput
            title={'five_4'}
            keyC={'five_4'}
            value={content?.five_4}
          />
          <ChangeTextInput
            title={'five_5'}
            keyC={'five_5'}
            value={content?.five_5}
          />
          <ChangeTextInput
            title={'five_6'}
            keyC={'five_6'}
            value={content?.five_6}
          />
          <ChangeTextInput
            title={'five_7'}
            keyC={'five_7'}
            value={content?.five_7}
          />
          <ChangeTextInput
            title={'five_8'}
            keyC={'five_8'}
            value={content?.five_8}
          />
          <ChangeTextInput
            title={'five_9'}
            keyC={'five_9'}
            value={content?.five_9}
          />
          <ChangeTextInput
            title={'five_10'}
            keyC={'five_10'}
            value={content?.five_10}
          />
          <ChangeTextInput
            title={'five_11'}
            keyC={'five_11'}
            value={content?.five_11}
          />
          <div className={classes.end} />
        </div>
      </div>,
      //****************************************************************************************************
      <div className={classes.main}>
        <div className={classes.desktop}>
          <ChangeVideoInput
            title={'six_video_1'}
            keyC={'six_video_1'}
            value={content?.six_video_1}
          />
          <ChangeTextInput
            title={'six_1'}
            keyC={'six_1'}
            value={content?.six_1}
          />
          <ChangeTextInput
            title={'six_2'}
            keyC={'six_2'}
            value={content?.six_2}
          />
          <ChangeTextInput
            title={'six_3'}
            keyC={'six_3'}
            value={content?.six_3}
          />
          <ChangeTextInput
            title={'six_4'}
            keyC={'six_4'}
            value={content?.six_4}
          />
          <div className={classes.end} />
        </div>
      </div>,
      //****************************************************************************************************
      <div className={classes.main}>
        <div className={classes.desktop}>
          <ChangeImageInput
            title={'QRCod'}
            keyC={'seven_img_1'}
            value={content?.seven_img_1}
          />
          <ChangeTextInput
            title={'seven_d_1'}
            keyC={'seven_d_1'}
            device={'d'}
            value={content?.seven_d_1}
          />
          <ChangeTextInput
            title={'seven_d_2'}
            keyC={'seven_d_2'}
            device={'d'}
            value={content?.seven_d_2}
          />
          <ChangeTextInput
            title={'seven_d_3'}
            keyC={'seven_d_3'}
            device={'d'}
            value={content?.seven_d_3}
          />
          <ChangeTextInput
            title={'seven_d_4'}
            keyC={'seven_d_4'}
            device={'d'}
            value={content?.seven_d_4}
          />
          <ChangeTextInput
            title={'seven_d_5'}
            keyC={'seven_d_5'}
            device={'d'}
            value={content?.seven_d_5}
          />
          <ChangeTextInput
            title={'seven_d_6'}
            keyC={'seven_d_6'}
            device={'d'}
            value={content?.seven_d_6}
          />
          <ChangeTextInput
            title={'seven_d_7'}
            keyC={'seven_d_7'}
            device={'d'}
            value={content?.seven_d_7}
          />
          <ChangeTextInput
            title={'seven_d_8'}
            keyC={'seven_d_8'}
            device={'d'}
            value={content?.seven_d_8}
          />
          <ChangeTextInput
            title={'seven_d_9'}
            keyC={'seven_d_9'}
            device={'d'}
            value={content?.seven_d_9}
          />
          <ChangeTextInput
            title={'seven_d_10'}
            keyC={'seven_d_10'}
            device={'d'}
            value={content?.seven_d_10}
          />
          <ChangeTextInput
            title={'seven_d_11'}
            keyC={'seven_d_11'}
            device={'d'}
            value={content?.seven_d_11}
          />
          <ChangeTextInput
            title={'seven_d_12'}
            keyC={'seven_d_12'}
            device={'d'}
            value={content?.seven_d_12}
          />
          <div className={classes.end} />
        </div>

        <div className={classes.mobile}>
          <ChangeTextInput
            title={'seven_m_1'}
            keyC={'seven_m_1'}
            device={'m'}
            value={content?.seven_m_1}
          />
          <ChangeTextInput
            title={'seven_m_2'}
            keyC={'seven_m_2'}
            device={'m'}
            value={content?.seven_m_2}
          />
          <ChangeTextInput
            title={'seven_m_3'}
            keyC={'seven_m_3'}
            device={'m'}
            value={content?.seven_m_3}
          />
          <ChangeTextInput
            title={'seven_m_4'}
            keyC={'seven_m_4'}
            device={'m'}
            value={content?.seven_m_4}
          />
          <ChangeTextInput
            title={'seven_m_5'}
            keyC={'seven_m_5'}
            device={'m'}
            value={content?.seven_m_5}
          />
          <ChangeTextInput
            title={'seven_m_6'}
            keyC={'seven_m_6'}
            device={'m'}
            value={content?.seven_m_6}
          />
          <ChangeTextInput
            title={'seven_m_7'}
            keyC={'seven_m_7'}
            device={'m'}
            value={content?.seven_m_7}
          />
          <ChangeTextInput
            title={'seven_m_8'}
            keyC={'seven_m_8'}
            device={'m'}
            value={content?.seven_m_8}
          />
          <ChangeTextInput
            title={'seven_m_9'}
            keyC={'seven_m_9'}
            device={'m'}
            value={content?.seven_m_9}
          />
          <ChangeTextInput
            title={'seven_m_10'}
            keyC={'seven_m_10'}
            device={'m'}
            value={content?.seven_m_10}
          />
          <ChangeTextInput
            title={'seven_m_11'}
            keyC={'seven_m_11'}
            device={'m'}
            value={content?.seven_m_11}
          />
          <ChangeTextInput
            title={'seven_m_12'}
            keyC={'seven_m_12'}
            device={'m'}
            value={content?.seven_m_12}
          />
          <div className={classes.end} />
        </div>
      </div>,
      //****************************************************************************************************
      <div className={classes.main}>
        <div className={classes.desktop}>
          <ChangeTextInput
            title={'eight_d_1'}
            keyC={'eight_d_1'}
            device={'d'}
            value={content?.eight_d_1}
          />
          <ChangeTextInput
            title={'eight_d_2'}
            keyC={'eight_d_2'}
            device={'d'}
            value={content?.eight_d_2}
          />
          <ChangeTextInput
            title={'eight_d_3'}
            keyC={'eight_d_3'}
            device={'d'}
            value={content?.eight_d_3}
          />
          <ChangeTextInput
            title={'eight_d_4'}
            keyC={'eight_d_4'}
            device={'d'}
            value={content?.eight_d_4}
          />
          <ChangeTextInput
            title={'eight_d_5'}
            keyC={'eight_d_5'}
            device={'d'}
            value={content?.eight_d_5}
          />
          <ChangeTextInput
            title={'eight_d_6'}
            keyC={'eight_d_6'}
            device={'d'}
            value={content?.eight_d_6}
          />
          <ChangeTextInput
            title={'eight_d_7'}
            keyC={'eight_d_7'}
            device={'d'}
            value={content?.eight_d_7}
          />
          <ChangeImageInput
            title={'eight_img_d_1'}
            keyC={'eight_img_d_1'}
            device={'d'}
            value={content?.eight_img_d_1}
          />
          <ChangeImageInput
            title={'eight_img_d_2'}
            keyC={'eight_img_d_2'}
            device={'d'}
            value={content?.eight_img_d_2}
          />
          <ChangeImageInput
            title={'eight_img_d_3'}
            keyC={'eight_img_d_3'}
            device={'d'}
            value={content?.eight_img_d_3}
          />
          <ChangeImageInput
            title={'eight_logo_d_1'}
            keyC={'eight_logo_d_1'}
            device={'d'}
            value={content?.eight_logo_d_1}
          />
          <div className={classes.end} />
        </div>

        <div className={classes.mobile}>
          <ChangeTextInput
            title={'eight_m_1'}
            keyC={'eight_m_1'}
            device={'m'}
            value={content?.eight_m_1}
          />
          <ChangeTextInput
            title={'eight_m_2'}
            keyC={'eight_m_2'}
            device={'m'}
            value={content?.eight_m_2}
          />
          <ChangeTextInput
            title={'eight_m_3'}
            keyC={'eight_m_3'}
            device={'m'}
            value={content?.eight_m_3}
          />
          <ChangeTextInput
            title={'eight_m_4'}
            keyC={'eight_m_4'}
            device={'m'}
            value={content?.eight_m_4}
          />
          <ChangeTextInput
            title={'eight_m_5'}
            keyC={'eight_m_5'}
            device={'m'}
            value={content?.eight_m_5}
          />
          <ChangeTextInput
            title={'eight_m_6'}
            keyC={'eight_m_6'}
            device={'m'}
            value={content?.eight_m_6}
          />
          <ChangeTextInput
            title={'eight_m_7'}
            keyC={'eight_m_7'}
            device={'m'}
            value={content?.eight_m_7}
          />
          <ChangeImageInput
            title={'eight_img_m_1'}
            keyC={'eight_img_m_1'}
            device={'m'}
            value={content?.eight_img_m_1}
          />
          <ChangeImageInput
            title={'eight_img_m_2'}
            keyC={'eight_img_m_2'}
            device={'m'}
            value={content?.eight_img_m_2}
          />
          <ChangeImageInput
            title={'eight_img_m_3'}
            keyC={'eight_img_m_3'}
            device={'m'}
            value={content?.eight_img_m_3}
          />
          <ChangeImageInput
            title={'eight_logo_m_1'}
            keyC={'eight_logo_m_1'}
            device={'m'}
            value={content?.eight_logo_m_1}
          />
          <div className={classes.end} />
        </div>
      </div>,
      //****************************************************************************************************
      <div className={classes.main}>
        <div className={classes.desktop}>
          <ChangeTextInput
            title={'nine_1'}
            keyC={'nine_1'}
            value={content?.nine_1}
          />
          <ChangeTextInput
            title={'nine_2'}
            keyC={'nine_2'}
            value={content?.nine_2}
          />
          <ChangeTextInput
            title={'nine_3'}
            keyC={'nine_3'}
            value={content?.nine_3}
          />
          <ChangeImageInput
            title={'nine_img_d_1'}
            keyC={'nine_img_d_1'}
            device={'d'}
            value={content?.nine_img_d_1}
          />
          <ChangeImageInput
            title={'nine_img_d_2'}
            keyC={'nine_img_d_2'}
            device={'d'}
            value={content?.nine_img_d_2}
          />
          <ChangeImageInput
            title={'nine_img_d_3'}
            keyC={'nine_img_d_3'}
            device={'d'}
            value={content?.nine_img_d_3}
          />
          <ChangeImageInput
            title={'nine_img_d_4'}
            keyC={'nine_img_d_4'}
            device={'d'}
            value={content?.nine_img_d_4}
          />
          <div className={classes.end} />
        </div>

        <div className={classes.mobile}>
          <ChangeImageInput
            title={'nine_img_m_1'}
            keyC={'nine_img_m_1'}
            device={'m'}
            value={content?.nine_img_m_1}
          />
          <ChangeImageInput
            title={'nine_img_m_2'}
            keyC={'nine_img_m_2'}
            device={'m'}
            value={content?.nine_img_m_2}
          />
        </div>
      </div>,
      //****************************************************************************************************
      <div className={classes.main}>
        <div className={classes.desktopPlus}>
          <ChangeReviewModule title={'Отзывы'} keyC={'ten_comments'} />
          <ChangeTextInput
            title={'ten_1'}
            keyC={'ten_1'}
            value={content?.ten_1}
          />
          <ChangeTextInput
            title={'ten_2'}
            keyC={'ten_2'}
            value={content?.ten_2}
          />
          <ChangeTextInput
            title={'ten_3'}
            keyC={'ten_3'}
            value={content?.nine_3}
          />
          <ChangeTextInput
            title={'ten_form_1'}
            keyC={'ten_form_1'}
            value={content?.ten_form_1}
          />
          <ChangeTextInput
            title={'ten_form_2'}
            keyC={'ten_form_2'}
            value={content?.ten_form_2}
          />
          <ChangeTextInput
            title={'ten_form_3'}
            keyC={'ten_form_3'}
            value={content?.ten_form_3}
          />
          <div className={classes.end} />
        </div>
      </div>,
      //****************************************************************************************************
      <div className={classes.main}>
        <div className={classes.desktop}>
          <ChangeTextInput
            title={'eleven_1'}
            keyC={'eleven_1'}
            value={content?.eleven_1}
          />
          <ChangeTextInput
            title={'eleven_2'}
            keyC={'eleven_2'}
            value={content?.eleven_2}
          />
          <ChangeTextInput
            title={'eleven_3'}
            keyC={'eleven_3'}
            value={content?.eleven_3}
          />
          <ChangeTextInput
            title={'eleven_4'}
            keyC={'eleven_4'}
            value={content?.eleven_4}
          />
          <ChangeTextInput
            title={'eleven_5'}
            keyC={'eleven_5'}
            value={content?.eleven_5}
          />
          <ChangeTextInput
            title={'eleven_6'}
            keyC={'eleven_6'}
            value={content?.eleven_6}
          />
          <ChangeTextInput
            title={'eleven_7'}
            keyC={'eleven_7'}
            value={content?.eleven_7}
          />
          <ChangeTextInput
            title={'eleven_8'}
            keyC={'eleven_8'}
            value={content?.eleven_8}
          />
          <ChangeTextInput
            title={'eleven_9'}
            keyC={'eleven_9'}
            value={content?.eleven_9}
          />
          <ChangeTextInput
            title={'eleven_10'}
            keyC={'eleven_10'}
            value={content?.eleven_10}
          />
          <ChangeTextInput
            title={'eleven_11'}
            keyC={'eleven_11'}
            value={content?.eleven_11}
          />
          <ChangeTextInput
            title={'eleven_12'}
            keyC={'eleven_12'}
            value={content?.eleven_12}
          />
          <div className={classes.end} />
        </div>
      </div>,
      //****************************************************************************************************
      <div className={classes.main}>
        <div className={classes.desktop}>
          <ChangeTextInput
            title={'twelve_1'}
            keyC={'twelve_1'}
            value={content?.twelve_1}
          />
          <ChangeTextInput
            title={'twelve_2'}
            keyC={'twelve_2'}
            value={content?.twelve_2}
          />
          <ChangeTextInput
            title={'twelve_3'}
            keyC={'twelve_3'}
            value={content?.twelve_3}
          />
          <ChangeTextInput
            title={'twelve_4'}
            keyC={'twelve_4'}
            value={content?.twelve_4}
          />

          <ChangeTextInput
            title={'twelve_link_vk_1'}
            keyC={'twelve_link_vk_1'}
            value={content?.twelve_link_vk_1}
          />
          <ChangeTextInput
            title={'twelve_link_youtube_1'}
            keyC={'twelve_link_youtube_1'}
            value={content?.twelve_link_youtube_1}
          />
          <ChangeTextInput
            title={'twelve_link_bezlimit_1'}
            keyC={'twelve_link_bezlimit_1'}
            value={content?.twelve_link_bezlimit_1}
          />

          <ChangeTextInput
            title={'twelve_d_1'}
            keyC={'twelve_d_1'}
            device={'d'}
            value={content?.twelve_d_1}
          />
          <ChangeTextInput
            title={'twelve_d_2'}
            keyC={'twelve_d_2'}
            device={'d'}
            value={content?.twelve_d_2}
          />
          <ChangeTextInput
            title={'twelve_d_3'}
            keyC={'twelve_d_3'}
            device={'d'}
            value={content?.twelve_d_3}
          />
          <ChangeTextInput
            title={'twelve_d_4'}
            keyC={'twelve_d_4'}
            device={'d'}
            value={content?.twelve_d_4}
          />
          <div className={classes.end} />
        </div>

        <div className={classes.mobile}>
          <ChangeTextInput
            title={'twelve_m_1'}
            keyC={'twelve_m_1'}
            device={'m'}
            value={content?.twelve_m_1}
          />
          <ChangeTextInput
            title={'twelve_m_2'}
            keyC={'twelve_m_2'}
            device={'m'}
            value={content?.twelve_m_2}
          />
          <ChangeTextInput
            title={'twelve_m_3'}
            keyC={'twelve_m_3'}
            device={'m'}
            value={content?.twelve_m_3}
          />
          <div className={classes.end} />
        </div>
      </div>,
      //****************************************************************************************************
      <div className={classes.main}>
        <div className={classes.desktop}>
          <ChangeTextInput
            title={'form_1'}
            keyC={'form_1'}
            value={content?.form_1}
          />
          <ChangeTextInput
            title={'form_2'}
            keyC={'form_2'}
            value={content?.form_2}
          />
          <ChangeTextInput
            title={'form_3'}
            keyC={'form_3'}
            value={content?.form_3}
          />
          <ChangeTextInput
            title={'form_4'}
            keyC={'form_4'}
            value={content?.form_4}
          />
          <ChangeTextInput
            title={'form_5'}
            keyC={'form_5'}
            value={content?.form_5}
          />
          <ChangeTextInput
            title={'form_6'}
            keyC={'form_6'}
            value={content?.form_6}
          />
          <ChangeTextInput
            title={'form_7'}
            keyC={'form_7'}
            value={content?.form_7}
          />
          <ChangeTextInput
            title={'form_8'}
            keyC={'form_8'}
            value={content?.form_8}
          />
          <ChangeTextInput
            title={'form_9'}
            keyC={'form_9'}
            value={content?.form_9}
          />

          <ChangeTextInput
            title={'form_form_1'}
            keyC={'form_form_1'}
            value={content?.form_form_1}
          />
          <ChangeTextInput
            title={'form_form_2'}
            keyC={'form_form_2'}
            value={content?.form_form_2}
          />
          <ChangeTextInput
            title={'form_form_3'}
            keyC={'form_form_3'}
            value={content?.form_form_3}
          />
          <div className={classes.end} />
        </div>

        <div className={classes.mobile}>
          <ButtonReset />
        </div>
      </div>,
    ]);
  }, []);

  const [isAuth, setIsAuth] = useState(false);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const AuthForm = async () => {
    if (password === '' || login === '') {
      setError('Все поля обязательны к заполнению');
      return;
    } else setError('');

    setLouder(true);
    const response = await AdminService.Auth(login, password);
    if (!response.status) {
      setError('Неверный логин или пароль');
    } else {
      localStorage.setItem('user_key', response.key);
      setError('');
      setIsAuth(true);
    }
    setLouder(false);
  };

  const checkAuth = async () => {
    setIsLouderAuth(true);
    if (await AdminService.CheckAuth()) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
      localStorage.removeItem('user_key');
    }
    setIsLouderAuth(false);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return isAuth ? (
    <div className={classes.Admin}>
      <div className={classes.AdminHeader}>
        <div className={classes.AdminHeaderPaginate}>
          <div
            onClick={setPageMinus}
            className={classes.AdminHeaderPaginateLeftButton}
          >
            <img src={rightB} alt="" />
          </div>
          <div className={classes.AdminHeaderPaginatePonel}>
            {pages.map((page, index) => {
              return (
                <div
                  key={index}
                  onClick={async () => {
                    setPageActive(index);
                    await AdminService.getAll();
                  }}
                  className={
                    pageActive === index
                      ? classes.AdminHeaderPaginatePonelButtonActive
                      : classes.AdminHeaderPaginatePonelButton
                  }
                >
                  {page}
                </div>
              );
            })}
          </div>
          <div
            onClick={setPagePlus}
            className={classes.AdminHeaderPaginateRigthButton}
          >
            <img src={leftB} alt="" />
          </div>
        </div>

        <div className={classes.AdminHeaderHelp}>
          ?
          <div className={classes.helpInfo}>
            <img src={helpInfoImg} alt="" />
          </div>
        </div>

        <div
          className={classes.AdminHeaderExit}
          onClick={() => {
            localStorage.removeItem('user_key');
            window.location.reload();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="533.333"
            height="533.333"
            x="0"
            y="0"
            enableBackground="new 0 0 533.333 533.333"
            version="1.1"
            viewBox="0 0 533.333 533.333"
            xmlSpace="preserve"
          >
            <path d="M416.667 333.333v-66.666H250V200h166.667v-66.667l100 100-100 100zM383.333 300v133.333H216.667v100l-200-100V0h366.667v166.667H350V33.333H83.333L216.667 100v300H350V300h33.333z"></path>
          </svg>
        </div>
      </div>

      {pagesContents[pageActive]}
    </div>
  ) : (
    <div className={classes.AdminAuth}>
      {isLouderAuth ? (
        <img src={loudergif} alt="" />
      ) : (
        <div className={classes.AuthForm}>
          <h1 className={classes.title}>Авторизация</h1>
          <input
            type="text"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            className={classes.adminFormInput}
            placeholder={'Логин'}
          />
          <input
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={classes.adminFormInput}
            placeholder={'Пароль'}
          />
          <p className={classes.errorText}>{error}</p>
          {louder ? (
            <button disabled={true} className={classes.adminFormButtonDisable}>
              <img src={loudergif} alt="" />
            </button>
          ) : (
            <button onClick={AuthForm} className={classes.adminFormButton}>
              Отправить
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Admin;
