import React, { useEffect, useState } from 'react';
import classes from './FormPage.module.css';
import InputMask from 'react-input-mask';
import PhoneService from '../../../Services/PhoneService';
import fall from '../../../images/fall.png';
import ok from '../../../images/active.png';

const FormPage = ({ phone, rates, isForm, setIsForm }) => {
  const [name, setName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userPhoneError, setUserPhoneError] = useState('');
  const [nameError, setNameError] = useState('');
  const [errorImg, setErrorImg] = useState();
  const [errPhone, setErrPhone] = useState();

  const requestPhone = async (e) => {
    e.preventDefault();

    if (!userPhone) {
      setUserPhoneError('Это поле обязательно для заполнения!');
      return;
    } else setNameError('');

    if (name.length < 1) {
      setNameError('Это поле обязательно для заполнения!');
      return;
    }
    if (name.length >= 1) {
      setNameError('');
    } else setNameError('');

    let uPhone =
      userPhone[3] +
      userPhone[4] +
      userPhone[5] +
      userPhone[7] +
      userPhone[8] +
      userPhone[9] +
      userPhone[11] +
      userPhone[12] +
      userPhone[14] +
      userPhone[15];

    // for(let i = 0; i<)
    if (uPhone.indexOf('_') >= 0) {
      setUserPhoneError('Введён некорректный номер телефона!');
      return;
    } else {
      setUserPhoneError('');
    }
    // if (phone == null) {
    //   return;
    // }

    setIsForm(2);
    const response = await PhoneService.postForm(phone, name, userPhone);
    response ? setIsForm(1) : setIsForm(3);
  };

  const request = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    if (name.length < 1) {
      setErrorImg(<img src={fall} alt="" />);
      return;
    } else setErrorImg('');
    if (name.length >= 1) {
      setErrorImg(<img src={ok} alt="" />);
      setNameError('');
    }
  }, [name.length]);

  useEffect(() => {
    if (userPhone.length == 0) {
      setErrPhone(<img src={fall} alt="" />);
      return;
    } else {
      setErrPhone(<img src={fall} alt="" />);
    }
    if (userPhone.split('_').length === 1) {
      setErrPhone(<img src={ok} alt="" />);
      setUserPhoneError('');
    }
  }, [userPhone]);

  return (
    <div className={classes.Page}>
      <div className={classes.container}>
        <div className={classes.content}>
          {isForm === 0 ? (
            <>
              <form className={'form-request'}>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Имя"
                  required={true}
                  className={classes.inputForm}
                />
                <div className={classes.inputError}>{nameError}</div>
                <div className="error-img-name">{errorImg}</div>
                <InputMask
                  value={userPhone}
                  onChange={(e) => setUserPhone(e.target.value)}
                  mask="+7 (999) 999-99-99"
                  type="phone"
                  placeholder="+7 (___) ___-__-__"
                  style={{ marginTop: '12px' }}
                  required={true}
                  className={classes.inputForm}
                />

                <div className={classes.inputError}>{userPhoneError}</div>
                <div className="error-img-phone">{errPhone}</div>
                <input
                  onClick={requestPhone}
                  type="submit"
                  value="Отправить"
                  className={classes.button}
                />
              </form>
            </>
          ) : isForm === 1 ? (
            <div className={classes.responseForm}>
              Ваша форма отправлена оператору!
            </div>
          ) : isForm === 2 ? (
            <div className={classes.responseForm}>Отправка...</div>
          ) : (
            <div className={classes.responseForm}>
              Произошла ошибка, пожалуйста попробуйте отправить заявку позже.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormPage;
