import React, { useRef, useState } from 'react';
import classes from './ChangeReviewForm.module.css';
import gifLouder from '../../../../images/Admin/louder.gif';
import ava_D from './../../../../images/Admin/ava_D.png';

const ChangeReviewForm = ({ save }) => {
  const [imgPrev, setImgPrev] = useState(ava_D);
  const [isFocus, setIsFocus] = useState(false);

  // Отвечают за вывод различных кнопок
  const [isError, setIsError] = useState(false);
  const [isLouder, setIsLouder] = useState(false);

  // Фунция рендеренга превью картинки
  async function handleFileSelect(evt) {
    let file = evt.target.files;
    let f = file[0];
    let reader = new FileReader();
    reader.onload = (function (theFile) {
      return function (e) {
        setImgPrev(e.target.result);
      };
    })(f);
    await reader.readAsDataURL(f);
  }
  const changeImg = async (e) => {
    await handleFileSelect(e);
    setImage(e.target.files[0]);
    // console.log(e.target.files[0]);
  };

  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [message, setMessage] = useState('');
  const [image, setImage] = useState(null);

  const [isNameError, setIsNameError] = useState(false);
  const [isCityError, setIsCityError] = useState(false);
  const [isMessageError, setIsMessageError] = useState(false);
  const [isImageError, setIsImageError] = useState(false);

  const validFalse = () => {
    setIsNameError(false);
    setIsCityError(false);
    setIsMessageError(false);
    setIsImageError(false);
  };

  const inputImg = useRef();

  const valueNull = () => {
    setName('');
    setCity('');
    setMessage('');
    setImage(null);
    setImgPrev(ava_D);
    inputImg.current.value = '';
  };

  const thisSave = async () => {
    // console.log(image)

    if (name === '') setIsNameError(true);
    else setIsNameError(false);

    if (city === '') setIsCityError(true);
    else setIsCityError(false);

    if (message === '') setIsMessageError(true);
    else setIsMessageError(false);

    // if (isNameError || isMessageError || isCityError) return;
    if (name === '' || city === '' || message === '') return;

    console.log(isNameError + ' || ' + isMessageError + ' || ' + isCityError);

    setIsLouder(true);

    const isSave = await save(name, city, message, image);

    if (isSave?.check) {
      setIsError(false);
      valueNull();
    } else {
      setIsError(true);
    }

    setIsLouder(false);
  };

  return (
    <div className={classes.ChangeReviewSliderItem}>
      <div className={classes.inputs}>
        <input
          type="file"
          id="file_create"
          ref={inputImg}
          onChange={(e) => changeImg(e)}
        />
        <label htmlFor="file_create" className={classes.fileLabel}>
          <img src={imgPrev} alt="" />
        </label>
        <input
          style={isNameError ? { border: 'red 2px solid' } : {}}
          type="text"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <input
          style={isCityError ? { border: 'red 2px solid' } : {}}
          type="text"
          onChange={(e) => setCity(e.target.value)}
          value={city}
        />
        <textarea
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          style={isMessageError ? { border: 'red 2px solid' } : {}}
          onChange={(e) => setMessage(e.target.value)}
          value={message}
        />
      </div>

      <div className={classes.buttons}>
        {isFocus ? (
          <></>
        ) : !isLouder ? (
          !isError ? (
            <button
              disabled={false}
              onClick={thisSave}
              className={classes.saveTrueButton}
            >
              {/*<img src={imgTrue} alt=""/>*/}
              Добавить +
            </button>
          ) : (
            <button
              disabled={false}
              onClick={thisSave}
              className={classes.saveErrorButton}
            >
              Ошибка!
            </button>
          )
        ) : (
          <button
            disabled={true}
            style={{ cursor: 'default' }}
            className={classes.saveButton}
          >
            <img src={gifLouder} alt="" />
          </button>
        )}
      </div>
    </div>
  );
};

export default ChangeReviewForm;
