import React, {useContext, useEffect, useState} from 'react';
import classes from './ChangeReviewModule.module.css'
import ChangeReviewSliderItem from "./ChangeReviewSliderItem/ChangeReviewSliderItem";
import {GlobalContext} from "../../../contexts/GlobalContext";
import AdminService from "../../../Services/AdminService";
import ChangeReviewForm from "./ChangeReviewForm/ChangeReviewForm";
import leftB from "../../../images/Admin/leftB.svg";
import rightB from "../../../images/Admin/rightB.svg";

const ChangeReviewModule = ({title = "", keyC = ""}) => {
    const {content, setContent} = useContext(GlobalContext);

    const [data, setData] = useState([]);

    const [sliderStep, setSliderStep] = useState(0);

    const sliderStepUp = () => {
        if(sliderStep<data.length-3){
            setSliderStep(sliderStep+1)
        }
    }

    const sliderStepDown = () => {
        if(sliderStep>0){
            setSliderStep(sliderStep-1)
        }

    }

    useEffect(() => {
        setData(content[keyC]);
    }, [content])

    const saveChangeItem = async (review) => {

        const response = await AdminService.UpdateReview(review.id, review.name, review.city, review.massage, review.img)

        const reviewResponse = await AdminService.GetAllReview();

        if (response?.check && reviewResponse?.check) setContent({
            ...content,
            [keyC]: reviewResponse?.ten_comments
        });

        return response;
        // return {check: true}
    }

    const DelChangeItem = async (review) => {
        const response = await AdminService.DellReview(review?.id)
        if (response.check) {
            let index_el = null;
            content[keyC].map((element, index) => {
                if (element.id === review.id) {
                    index_el = index;
                }
            })

            data.splice(index_el, 1)

            if (!(index_el === null)) setContent({
                ...content,
                [keyC]: data
            });
        }
        return response;
    }

    const createReview = async (name, city, message, image) => {
        const response = await AdminService.CreateReview(name, city, message, image)

        const reviewResponse = await AdminService.GetAllReview();
        console.log(reviewResponse)

        if (response?.check && reviewResponse?.check) setContent({
            ...content,
            [keyC]: reviewResponse?.ten_comments
        });

        return response;
    }


    return (
        <div className={classes.ChangeReviewModule}>
            <h1 className={classes.title}>
                {title}
            </h1>

            <div className={classes.ChangeReviewModuleDiv}>
                <div className={classes.slider}>
                    <button className={classes.sliderB} onClick={sliderStepDown}>
                        <img src={rightB} alt=""/>

                    </button>
                    <div className={classes.sliderContent} >
                    {
                        data?.map((element, index) => {
                            return (
                                <div key={element.id}
                                    style={{
                                        transform:"translateX("+(-270*sliderStep)+"px)",
                                        transition:"0.5s"
                                }}
                                >
                                <ChangeReviewSliderItem   data={element} save={saveChangeItem}
                                                        dell={DelChangeItem}/>
                                </div>
                            )
                        })
                    }
                    </div>
                    <button className={classes.sliderB} onClick={sliderStepUp}>
                        <img src={leftB} alt=""/>
                    </button>
                </div>
                <div className={classes.createForm}>
                    <ChangeReviewForm save={createReview} />
                </div>
            </div>
        </div>
    );
};

export default ChangeReviewModule;